import React, { useState,useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import { Switch, Route, Redirect } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useSelector,useDispatch,connect } from 'react-redux';
import ab2str from 'arraybuffer-to-string';
// components

import CardTable from "components/Cards/CardTable.js";
import DataAction from "actions/data";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import fileData from "./fileData";
import FetchData from "./fetchData";
import host from "config/host";
import Tour from "reactour";
import "./styles.css";
function Tables(props) {
  const [tour,setTour]=useState(false);
  const [tourOpen,setTourOpen]=useState(false);
  const dispatch = useDispatch();
  const [file,setFile] = useState(false);
  const [data,setData]=useState(null);
  const [role,setRole] = useState(null);
  props.login.then(val=>{
    console.log(val)
    setRole(val.role)
  })
  props.data.then(value=>{
    setData(value.data)
    setFile(true)
   
  })
  const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

  useEffect(()=>{
    console.log("Data:",data);
    setTimeout(()=>{
      if(data!=undefined && data.count==0){
        setTourOpen(true);
      }
    },1000)
  },[data])
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = async (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        setData(data);
        await dispatch(DataAction(data))
        setFile(true)
      };

      fileReader.onerror = (error) => {
        console.log(error);
      }
    })
    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const tourConfig = [
    {
      selector: '[data-tut="modomagic"]',
      content: `Let modo curate and provide you with best pool of applicants to review.`
    },
    {
      selector: '[data-tut="modomagic"]',
      content: `Getting your applicants curated is very simple`
    },
    {
      selector: '[data-tut="modomagic"]',
      content: `Click here`
    }
  ]
  return (
    <>
     <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
          
        />
    
    <Switch >
           <Route  path="/admin/tables/fetchdata" exact component={FetchData} /> 
           <Route path="/admin/tables" exact component={fileData} />
            <Redirect from="/admin" to="/" />
          </Switch> 
  </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
  login :state.LoginReducer
});

export default connect(mapStateToProps)(Tables)