import React, { useState } from 'react'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DropDownMenu, MenuItem, Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import host from 'config/host';
function PasswordChange() {
   

      const hideSnackbar=async()=>{
          setTimeout(()=>{
            showSnackbar(false)
          },1000)
      }

      const ChangePassword = async()=>{
        if(currentPassword===""){
            setMessage("Please provide current password");
            showSnackbar(true);
            hideSnackbar()
        }
        else if(newPassword.length<8){
            setMessage("New Password must be atleast 8 characters");
            showSnackbar(true);
            hideSnackbar();
        }
        else if(newPassword!==confirmPassword){
            setMessage("Passwords don't match");
            showSnackbar(true);
            hideSnackbar();
        }
        else{
            // setisloading(true);
            // fetch(host.nameserver + '/org/addUser', {
            //     method: 'POST',
            //     headers: {
            //       'Content-type': 'application/json; charset=UTF-8',
            //       'Authorization':'Bearer '+localStorage.getItem('token')
            //     },
            //     body: JSON.stringify({
            //         userEmail : email,
            //         userRole: role
            //     })
            //   })
            //     .then((res) => {
            //         setisloading(false)
            //       if (res.status === 200) return res.json();
            //     }).then((data)=>{
            //         setMessage("user added successfully");
            //         showSnackbar(true);
            //         hideSnackbar()
            //         setEmail("")
            //         setRole("please select role")
            //     }).catch((err)=>{
            //         setisloading(false)
            //         console.log(err);
            //         setMessage("something went wrong");
            //         showSnackbar(true);
            //         hideSnackbar()
            //     })
        

        }
      }
      const [currentPassword, setCurrentPassword] = useState("")
      const [newPassword,setNewPassword] =useState("");
      const [confirmPassword,setConfirmPassword] = useState("")
      const [isloading,setisloading] = useState(false);
      const [message,setMessage]=useState("");
      const [snackbar,showSnackbar]=useState(false);
      
    return (
        <>
             <div className="container mx-auto px-4 w-full h-full">
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center w-full h-full">
          <div className="w-full lg:w-8/12 px-4">
          <LoadingOverlay
      active={isloading}
  spinner
  text='Loading please wait...'
  >
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Change My Password</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Current Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Icecream Labs"
                      value={currentPassword}
                      onChange={(e)=>{setCurrentPassword(e.target.value)}}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     New Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Icecream Labs"
                      value={newPassword}
                      onChange={(e)=>{setNewPassword(e.target.value)}}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Confirm New Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Icecream Labs"
                      value={confirmPassword}
                      onChange={(e)=>{setConfirmPassword(e.target.value)}}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    onClick={()=>{ChangePassword()}}
                    >
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
            </LoadingOverlay>
          </div>
          
        </div>
      </div>
        </>
    )
}

export default PasswordChange
