import host from 'config/host';
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay';

function ViewApis() {
    const [keys, setKeys] = useState(null);
    const [loading,setLoading]= useState(true);
    useEffect(() => {
       getKeys()
    
    }, [])
    

    const deactivateApi=async(item)=>{
      fetch(host.nameserver + '/org/apikeydeactivate', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization':'Bearer '+localStorage.getItem('token')
        },
        body:JSON.stringify({
            email:localStorage.getItem('email'),
            _id:item['_id']
        })
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        }).then((data)=>{
            getKeys();
        }).catch((err)=>{
            console.log(err);
        })
    }

    const getKeys=()=>{
      setLoading(true);
        fetch(host.nameserver + '/org/apikeys', {
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'Authorization':'Bearer '+localStorage.getItem('token')
            },
            body:JSON.stringify({
                email:localStorage.getItem('email')
            })
          })
            .then((res) => {
              if (res.status === 200) return res.json();
            }).then((data)=>{
                console.log(data);
                setKeys(data.apikeys);
                setLoading(false);
            }).catch((err)=>{
                console.log(err);
                setLoading(false);
            })
    }
    return (
        <>
        {keys && keys.length>0 ? 
         <LoadingOverlay
         active={loading}
     spinner
     text='Loading please wait...'
     >
        <div className="block w-full overflow-x-auto" style={{height:100+"vh",justifyContent:"center",display:"flex",alignItems:"flex-start",marginTop:120}}>
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16,paddingLeft:35}}
              >
               Name of API key
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16}}
              >
               Created time
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                 "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16}}
              >
               Last used
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                 "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16,paddingLeft:20}}
              >
               Change status
              </th>
            </tr>
          </thead>
          <tbody>
            {keys.map((item)=>{
              return(
              <tr style={{cursor:item['Status']=='complete' ? "pointer":"default"}}>
              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" style={{fontSize:15,fontWeight:'inherit',paddingLeft:35}}>
                <span
                  
                >
               {item["name"]} 
                </span>
              </th>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{fontSize:15}}>
              {new Date(item["createdAt"]).toLocaleString('en-uk')}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{fontSize:15}}>
                <div className="flex">
             {item['lastActiveAt'] === undefined ? 'No Last Used Date': new Date(item['lastActiveAt']).toLocaleString('en-uk')}
                </div> 
              </td>
              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2">
        
                <button onClick={()=>{if(item['deactivated']==false){ deactivateApi(item);}}} style={{color:item['deactivated']==false ? '#7f0000':'grey',padding:5,backgroundColor:'white',borderRadius:10,width:80,fontSize:15}}>
                   {item['deactivated']==false ? 'Deactivate': 'Deactivated'}
             </button>
              </td>
            </tr>)
            })}
          </tbody>
        </table>
      </div> </LoadingOverlay>
      :<h1 style={{justifyContent:"center",alignItems:"center",display:"flex",height:100+"vh"}}>{loading?"Loading please wait..":"No data to show"}</h1>}
       
        </>
    )
}

export default ViewApis
