const ResultFilterAction  = (data)=> async dispatch=>{
    try{ 
        dispatch({
                type:'FILTERRESULT',
                payload:{data:data}
        })
    }catch(err){
        console.log(err)
        dispatch({
            type:"RESULTFAIL"
        })
    }

}
export default ResultFilterAction;