import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect, useDispatch, useSelector} from 'react-redux'
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import host from "config/host";
import Jobview from "views/jobView/jobview";
import { ResultsView } from "views/admin/results";
import { JobResultsHeader } from "components/Headers/jobResultsHeader";
import Tour from "reactour";
import LoginAction from "actions/login";


 function ResultsLayout(props) {
  const [tourOpen,setTourOpen]=useState(true);
  const [tour,setTour] = useState(false);
  const [loggedin, setLoggedin] = useState(true);
  const [role,setRole] = useState('owner');
  const dispatch = useDispatch()
  const loginstatus = useSelector(state => state.LoginReducer);
  loginstatus.then(val=>{
    if(val.Loggedin===false){
   if(!localStorage.getItem('loggedin')===true){
    props.history.push('/');
   }
    }
  })
  useEffect(() => {
    setLoggedin(localStorage.getItem('loggedin'));
    setRole(localStorage.getItem('role'));
    if(localStorage.getItem('loggedin'))
     dispatch(LoginAction(localStorage.getItem('email'),localStorage.getItem('role'),false));
  }, [])
 
   const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

   const tourConfig = [
    {
      selector: '[data-tut="pheader"]',
      content: `To help you sort the data better, you can use the available two filters and Filter.`
    },
    {
      selector: '[data-tut="checkresult"]',
      content: `This is final predictions, You can download your results by clicking right on top.`
    },
    {
      selector: '[data-tut="dashboard"]',
      content: `Click here to go to dashboard`
    },
    
  ]
   const [loginState, setLoginState] = useState(null)
  return (
    <>
    <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
      <Sidebar data-tut="dashboard"  />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar props={props} />
        {/* Header */}
        <JobResultsHeader data-tut="pheader"  />
        <div data-tut="checkresult" className="px-4 md:px-10 mx-auto w-full -m-24">
          {loggedin ?
          <Switch>
           {loggedin &&  <Route path="/results/view" exact component={ResultsView} />}
            <Redirect from="/results" to="/" />
          </Switch> : <h3>Loading</h3> }
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
});

export default connect(mapStateToProps)(ResultsLayout)