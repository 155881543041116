import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DropDownMenu, MenuItem, Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ClientTable from './clientTable';
import host from 'config/host';

function AllClients() {
 const [data, setData] = useState(null)
 useEffect(() => {
   
     fetch(host.nameserver + '/org/get', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization':'Bearer '+localStorage.getItem('token')
        },
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        }).then((data)=>{
            console.log(data);
            setData(data.organisations)
        }).catch((err)=>{
            console.log(err);
        })

 }, [])
    return (
        <>
      <div className="container mx-auto px-4 w-full h-full">
   {data==null? <h3>Nothing to show</h3> :<ClientTable color={"light"} data={data} ></ClientTable>}
      </div>
    </>
    )
}

export default AllClients
