import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect, useDispatch, useSelector} from 'react-redux'
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import host from "config/host";
import Jobview from "views/jobView/jobview";
import { Results } from "views/admin/results";
import LoginAction from "actions/login";


 function Admin(props) {
  const [loggedin, setLoggedin] = useState(true);
  const [role,setRole] = useState(null);
  const dispatch = useDispatch()
  const loginstatus = useSelector(state => state.LoginReducer);
  loginstatus.then(val=>{
    if(val.Loggedin===false){
   if(!localStorage.getItem('loggedin')===true){
    props.history.push('/');
   }
    }
  })
  useEffect(() => {
    setLoggedin(localStorage.getItem('loggedin'));
    setRole(localStorage.getItem('role'));
    if(localStorage.getItem('loggedin'))
     dispatch(LoginAction(localStorage.getItem('email'),localStorage.getItem('role'),false));
  }, [])
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar props={props} />
        {/* Header */}
        <HeaderStats location={props.history.location.pathname} history={props.history} />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
           {loggedin &&  <Route path="/admin/dashboard" exact component={Dashboard} />}
           {loggedin && <Route path="/admin/tables" exact component={Tables} /> }
           {loggedin && <Route path="/admin/tables/fetchdata" exact component={Tables} /> }
            <Redirect from="/admin" to="/" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
});

export default connect(mapStateToProps)(Admin)