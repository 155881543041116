import CardJobDetails from 'components/Cards/CardJobDetails'
import React, { useEffect, useState } from 'react'
import {connect, useDispatch} from 'react-redux'

function Jobview(props) {
    const [jobdata, setJobData] = useState(null)
    props.jobdata.then(data=>{
        setJobData(data.jobdata)
    })
  
    return (
        <>
        <div style={{height:80+'vh'}}>
      {jobdata ? <CardJobDetails data={jobdata} props={props}></CardJobDetails>:<h3 style={{display:'flex',justifyContent:'center',alignItems:'center',height:80+'vh'}}>Loading please wait...</h3>}
        </div>
        </>
    )
}
const mapStateToProps = state => ({
    loginstate: state.LoginReducer,
    jobdata:state.JobDataReducer
  });
  
  export default connect(mapStateToProps)(Jobview)
