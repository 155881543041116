import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import download from 'downloadjs';
import "./styles.css";
// components
import Pagination from 'react-responsive-pagination';
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import host from "config/host";
import {FaDownload} from "react-icons/fa"
import LoadingOverlay from "react-loading-overlay";
import { useSelector,useDispatch,connect } from 'react-redux';
import ResultFilterAction from "actions/resultsFilter";

 function ResultsTable({ color,filter }) {
  filter.then(value=>{
    setjobs(value.jobs);
    setLevel1(value.level1);
    setLevel2(value.level2);
    setLevel3(value.level3);
    setLevel4(value.level4);
    setLevel5(value.level5);
    setIsLoading(value.loading)
    setTotalpageno( Math.ceil(value.totalpageno))
    if(value.data && value.data.results!=null)
    setdata(value.data)
    
  })

  const [isloading,setIsLoading] = useState(false);
  const [data,setdata]=useState(null);
  const [pageno,setpageno] = useState(0)
  const [totalpageno,setTotalpageno] = useState(0)
  const [jobs,setjobs] = useState(null);
  const [level1,setLevel1] = useState(true);
  const [level2,setLevel2] = useState(true);
  const [level3,setLevel3] = useState(true);
  const [level4,setLevel4] = useState(true);
  const [level5,setLevel5] = useState(true);
 const dispatch = useDispatch();
  const DownloadFile = async()=>{
    setIsLoading(true);
    try{
    await fetch(host.nameserver+'/file/results',{
      method:'POST',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      'Content-type': 'application/json; charset=UTF-8',
    },
    body:JSON.stringify({
      email:localStorage.getItem('email'),
      offset:25,
      pageno:pageno,
      levels:JSON.stringify({level1,level2,level3,level4,level5}),
      jobs:jobs
    })
    }).then((res)=>{
      return res.json();
    }).then(async (data)=>{
      await dispatch(ResultFilterAction({
        jobs:jobs,
        levels:{level1,level2,level3,level4,level5},
        data:data,
        loading:false,
        totalpageno:Math.ceil(data.count/25)
    }))
     if(data.count>0)
      setTotalpageno(Math.ceil(data.count/25))
     await setdata(data);
      setIsLoading(false);
    })
  }catch(err){
    console.log(err)
    setIsLoading(false);
  }
  }


  const DownloadResultFile = async()=>{
    setIsLoading(true);
    try{
    const res = await fetch(host.nameserver+'/file/downloadresults',{
      method:'POST',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      'Content-type': 'application/json; charset=UTF-8',
    },
    body:JSON.stringify({
      email:localStorage.getItem('email'),
      offset:25,
      pageno:pageno,
      levels:JSON.stringify({level1,level2,level3,level4,level5}),
      jobs:jobs
    })
    })
    const blob = await res.blob();
   download(blob, "results.csv");
   setIsLoading(false);
   dispatch(ResultFilterAction({
    jobs:jobs,
    levels:{level1,level2,level3,level4,level5},
    data:data,
    loading:false,
    totalpageno:Math.ceil(data.count/25)
}))
  }catch(err){
    console.log(err)
    setIsLoading(false);
  }
  }

  useEffect(()=>{
    if(jobs!=null){
    setpageno(1)
    }
  },[jobs,level1,level2,level3,level4,level5])

  useEffect(() => {
    if(jobs!=null){
      
        DownloadFile()
    
    }
  }, [pageno])




  return (
    <>
    <LoadingOverlay
     active={isloading && data!=null}
     spinner
     text='Downloading please wait...'
     style={{display:'flex',justifyContent:"center",alignItems:'center'}}
    >
     {(data!=null && data.results!=null && data.results.length>0) ? <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 mt-32 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
               Applications predictions
              </h3>
              <button style={{backgroundColor:'#87C1F1',padding:5,color:'white',borderRadius:5,fontWeight:'bold',height:40}} onClick={()=>{DownloadResultFile()}}><div style={{flexDirection:'row'}}>Download results <FaDownload style={{display:'inline',alignItems:'center',justifyContent:'center',paddingLeft:2}}></FaDownload> </div> </button>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-end border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{textAlign:"left",paddingLeft:38}}
                >
                  AMCAS 
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                 Job number
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs capitalise border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                 Prediction
                </th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((item)=>{
                return(
                <tr key={item._id}>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                {item.amcas_id}
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.jobId}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.prediction.split(' ')[1]}
                </td>
              </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>:<div style={{display:"flex",justifyContent:"center",alignItems:"center",height:60+'vh',marginTop:20+'vh'}}>No predictions available yet</div>}
     {data && totalpageno>1 && <Pagination
     current={pageno}
     total={totalpageno}
     onPageChange={(num)=>{setpageno(num)}}
    //   onPageChange={(num)=>{setCurrentPage(num); props.history.push('#')}}
    />}
      </LoadingOverlay>
    </>
  );
}

ResultsTable.defaultProps = {
  color: "light",
};

ResultsTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

const mapStateToProps = state => ({
  filter: state.ResultsFilterReducer
});

export default connect(mapStateToProps)(ResultsTable)