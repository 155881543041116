import React, { useEffect, useState } from "react";

// components

import CardLineChart from "components/Cards/CardLineChart.js";
import HighestScore from "components/Cards/highestscore";
import McatScore from "components/Cards/mcatscore";
import GpaChart from "components/Cards/gpa"
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import Ethnicity from "components/Cards/ethnicity";
import { useSelector,useDispatch,connect } from 'react-redux';
import Race from "components/Cards/race";
import OwnerHeader from "components/Headers/ownerHeader";
import host from "config/host";
import DataAction from "actions/data";
import Tour from "reactour";
import DashBoardDataAction from "actions/dashboard";
import ResultsDataAction from "actions/resultsdata";
import JobsChart from "components/Cards/JobsLineChart";

function Dashboard(props) {
  const[data,setData]=useState(null)
  const [racedata,setracedata] = useState(null)
  const [ethnicitydata,setethnicitydata] = useState(null);
  const [totalscoredata,settotalscoredata] = useState(null);
  const [cgpadata,setcgpadata] = useState(null)
  const [lineGraphData,setLineGraphData] = useState(null)
  const dispatch = useDispatch();
 props.dashboardData.then(value=>{
   if(value.data!=null){
    setData(value.data)
    if(value.data!=undefined && value.data.length>0){
      value.data.map((graphdata)=>{
        if(graphdata!=undefined && graphdata.type=="ethnicity_cd")
        setethnicitydata(graphdata.data)
        else if(graphdata!=undefined && graphdata.type=="race_cd")
        setracedata(graphdata.data)
        else if(graphdata!=undefined && graphdata.type=="highest_total_score_range")
        settotalscoredata(graphdata.data);
        else if(graphdata!=undefined && graphdata.type=="cum_gpa_range")
        setcgpadata(graphdata.data)
      })
   }
  }
 })

 const convertToPercentage = (values)=>{
   const sum = values.reduce((a, b) => a + b, 0)
   let percentageArray = []
   if(sum!=0){
   for(let i=0;i<values.length;i++){
    percentageArray.push((values[i]/sum).toFixed(2))
   }
  }
  return percentageArray;
 }
 const [tourOpen,setTourOpen]=useState(true)
 const [tour,setTour]=useState(false);
 useEffect(() => {
   setTimeout(()=>{
   if(localStorage.getItem('token')){

    fetch(host.nameserver + '/dashboard/', {
      method: 'POST',
      body:JSON.stringify({year:2021}),
      headers: {
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async(data) => {
        dispatch(DashBoardDataAction(data.data))
        dispatch(ResultsDataAction(data.results))
       setLineGraphData( data.jobsdata)
      })
      .catch((err) => {
          console.log(err)
      });


  fetch(host.nameserver + '/file/?limit=10&skip=0', {
    method: 'GET',
    headers: {
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((res) => {
      if (res.status === 200) return res.json();
    })
    .then(async(data) => {
        dispatch(DataAction(data))
    })
    .catch((err) => {
        console.log(err)
    });
  }
},500)
}, [])
const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

  const [LineGraphx, setLineGraphX] = useState(null)
  const [LineGraphy, setLineGraphY] = useState(null)

  useEffect(()=>{
    if(lineGraphData!=null && lineGraphData.length>0){
      let linegraphx=[];
      let linegraphy=[];
      lineGraphData.map((jobrecord)=>{
        console.log(jobrecord.records)
        linegraphx.push(jobrecord.records);
        linegraphy.push(new Date(jobrecord.createdAt).toLocaleDateString());
      })
      console.log(linegraphx)
      console.log(linegraphy)
      setLineGraphX(linegraphx)
      setLineGraphY(linegraphy)
      console.log(LineGraphy)
    }
  },[lineGraphData])

  const [racexaxis,setraceXaxis] = useState(null)
  const [raceyaxis,setraceYaxis] = useState(null)
  const [racelevel1data,setracelevel1]=useState(null)
  const [racelevel2data,setracelevel2]=useState(null)
  const [racelevel3data,setracelevel3]=useState(null)
  const [racelevel4data,setracelevel4]=useState(null)
  const [racelevel5data,setracelevel5]=useState(null) 

useEffect(()=>{
  if(racedata!=null){
    setraceXaxis(racedata['x-vals']);
    setraceYaxis(racedata['y-vals']);
  }
},[racedata])

useEffect(()=>{
  let level1=[]
  let level2=[]
  let level3=[]
  let level4=[]
  let level5=[]
  if(racexaxis!=null && raceyaxis!=null && racexaxis.length>0){
  for(let i=0;i<racexaxis.length;i++){
    const values = [raceyaxis[i]["1"],raceyaxis[i]["2"],raceyaxis[i]["3"],raceyaxis[i]["4"],raceyaxis[i]["5"]]
    const sum = values.reduce((a, b) => a + b, 0)
    if(sum!=0){
  level1.push(((raceyaxis[i]["1"]/sum)*100).toFixed(2))
  level2.push(((raceyaxis[i]["2"]/sum)*100).toFixed(2))
  level3.push(((raceyaxis[i]["3"]/sum)*100).toFixed(2))
  level4.push(((raceyaxis[i]["4"]/sum)*100).toFixed(2))
  level5.push(((raceyaxis[i]["5"]/sum)*100).toFixed(2))
    }
    else{
      level1.push(0)
  level2.push(0)
  level3.push(0)
  level4.push(0)
  level5.push(0)
    }}
  setracelevel1(level1)
  setracelevel2(level2)
  setracelevel3(level3)
  setracelevel4(level4)
  setracelevel5(level5)
}
},[racexaxis,raceyaxis])


const [ethnicityxaxis,setethnicityXaxis] = useState(null)
const [ethnicityyaxis,setethnicityYaxis] = useState(null)
const [ethniclevel1data,setethniclevel1]=useState(null)
const [ethniclevel2data,setethniclevel2]=useState(null)
const [ethniclevel3data,setethniclevel3]=useState(null)
const [ethniclevel4data,setethniclevel4]=useState(null)
const [ethniclevel5data,setethniclevel5]=useState(null) 

useEffect(()=>{
  console.log(ethnicitydata)
if(ethnicitydata!=null){
  setethnicityXaxis(ethnicitydata['x-vals']);
  setethnicityYaxis(ethnicitydata['y-vals']);
}
},[ethnicitydata])

useEffect(()=>{
let level1=[]
let level2=[]
let level3=[]
let level4=[]
let level5=[]
if(ethnicityxaxis!=null && ethnicityyaxis!=null && ethnicityxaxis.length>0){
for(let i=0;i<ethnicityxaxis.length;i++){
  
  const values = [ethnicityyaxis[i]["1"],ethnicityyaxis[i]["2"],ethnicityyaxis[i]["3"],ethnicityyaxis[i]["4"],ethnicityyaxis[i]["5"]]
  const sum = values.reduce((a, b) => a + b, 0)
  if(sum!=0){
level1.push(((ethnicityyaxis[i]["1"]/sum)*100).toFixed(2))
level2.push(((ethnicityyaxis[i]["2"]/sum)*100).toFixed(2))
level3.push(((ethnicityyaxis[i]["3"]/sum)*100).toFixed(2))
level4.push(((ethnicityyaxis[i]["4"]/sum)*100).toFixed(2))
level5.push(((ethnicityyaxis[i]["5"]/sum)*100).toFixed(2))
  }
  else{
    level1.push(0)
level2.push(0)
level3.push(0)
level4.push(0)
level5.push(0)
  }}
setethniclevel1(level1)
setethniclevel2(level2)
setethniclevel3(level3)
setethniclevel4(level4)
setethniclevel5(level5)
}
},[ethnicityxaxis,ethnicityyaxis])


const [tsxaxis,settsXaxis] = useState(null)
const [tsyaxis,settsYaxis] = useState(null)
const [tslevel1data,settslevel1]=useState(null)
const [tslevel2data,settslevel2]=useState(null)
const [tslevel3data,settslevel3]=useState(null)
const [tslevel4data,settslevel4]=useState(null)
const [tslevel5data,settslevel5]=useState(null) 

useEffect(()=>{
if(totalscoredata!=null){
  settsXaxis(totalscoredata['x-vals']);
  settsYaxis(totalscoredata['y-vals']);
}
},[totalscoredata])

useEffect(()=>{
let level1=[]
let level2=[]
let level3=[]
let level4=[]
let level5=[]
if(tsxaxis!=null && tsyaxis!=null && tsxaxis.length>0){
for(let i=0;i<tsxaxis.length;i++){
  const values = [tsyaxis[i]["1"],tsyaxis[i]["2"],tsyaxis[i]["3"],tsyaxis[i]["4"],tsyaxis[i]["5"]]
  const sum = values.reduce((a, b) => a + b, 0)
  if(sum!=0){
level1.push(((tsyaxis[i]["1"]/sum)*100).toFixed(2))
level2.push(((tsyaxis[i]["2"]/sum)*100).toFixed(2))
level3.push(((tsyaxis[i]["3"]/sum)*100).toFixed(2))
level4.push(((tsyaxis[i]["4"]/sum)*100).toFixed(2))
level5.push(((tsyaxis[i]["5"]/sum)*100).toFixed(2))
  }
  else{
    level1.push(0)
level2.push(0)
level3.push(0)
level4.push(0)
level5.push(0)
  }
}
settslevel1(level1)
settslevel2(level2)
settslevel3(level3)
settslevel4(level4)
settslevel5(level5)
}
},[tsxaxis,tsyaxis])


const [cgpaxaxis,setcgpaXaxis] = useState(null)
const [cpgayaxis,setcgpaYaxis] = useState(null)
const [cpgalevel1data,setcgpalevel1]=useState(null)
const [cgpalevel2data,setcgpalevel2]=useState(null)
const [cgpalevel3data,setcgpalevel3]=useState(null)
const [cgpalevel4data,setcgpalevel4]=useState(null)
const [cgpalevel5data,setcgpalevel5]=useState(null) 

useEffect(()=>{
if(cgpadata!=null){
  setcgpaXaxis(cgpadata['x-vals']);
  setcgpaYaxis(cgpadata['y-vals']);
}
},[cgpadata])

useEffect(()=>{
let level1=[]
let level2=[]
let level3=[]
let level4=[]
let level5=[]
if(cgpaxaxis!=null && cpgayaxis!=null && cgpaxaxis.length>0){
for(let i=0;i<cgpaxaxis.length;i++){
  const values = [cpgayaxis[i]["1"],cpgayaxis[i]["2"],cpgayaxis[i]["3"],cpgayaxis[i]["4"],cpgayaxis[i]["5"]]
  const sum = values.reduce((a, b) => a + b, 0)
  if(sum!=0){
level1.push(((cpgayaxis[i]["1"]/sum)*100).toFixed(2))
level2.push(((cpgayaxis[i]["2"]/sum)*100).toFixed(2))
level3.push(((cpgayaxis[i]["3"]/sum)*100).toFixed(2))
level4.push(((cpgayaxis[i]["4"]/sum)*100).toFixed(2))
level5.push(((cpgayaxis[i]["5"]/sum)*100).toFixed(2))
  }
  else{
    level1.push(0)
level2.push(0)
level3.push(0)
level4.push(0)
level5.push(0)
  }
}
setcgpalevel1(level1)
setcgpalevel2(level2)
setcgpalevel3(level3)
setcgpalevel4(level4)
setcgpalevel5(level5)
}
},[cgpaxaxis,cpgayaxis])





const tourConfig = [
  {
    selector: '[data-tut="dashboard-graph"]',
    content: `This is an overview of modo predictions on your uploaded applicants.`
  },
  {
    selector: '[data-tut="graph1"]',
    content: `The graphs are to check that predictions are impartial.`
  },
  {
    selector: '[data-tut="manage-user"]',
    content: `Click here to explore Manage users`
  }
]
  return (
    <>
    <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />

      {data && (cgpaxaxis||ethnicityxaxis||racexaxis||tsxaxis||LineGraphx) ? <div data-tut="dashboard-graph" className="flex flex-wrap" style={{marginTop:120}}>
      {lineGraphData && LineGraphx && LineGraphy && <div data-tut="graph1" className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
        <JobsChart  data={{xaxis:LineGraphx,yaxis:LineGraphy}} />
        </div>}
        {tsxaxis && tsyaxis && tslevel1data && tslevel2data && tslevel3data && tslevel4data && tslevel5data &&
          <div data-tut="graph1" className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
        <HighestScore  data={{xaxis:tsxaxis,yaxis:{level1:tslevel1data,level2:tslevel2data,level3:tslevel3data,level4:tslevel4data,level5:tslevel5data}}} />
        </div> }
      {cgpaxaxis && cpgayaxis && cpgalevel1data && cgpalevel2data && cgpalevel3data && cgpalevel4data && cgpalevel5data && <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
        <GpaChart data={{xaxis:cgpaxaxis,yaxis:{level1:cpgalevel1data,level2:cgpalevel2data,level3:cgpalevel3data,level4:cgpalevel4data,level5:cgpalevel5data}}}  />
        </div>
        }
        {ethnicityxaxis && ethnicityyaxis && ethniclevel1data && ethniclevel2data && ethniclevel3data && ethniclevel4data && ethniclevel5data && <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
        <Ethnicity data={{xaxis:ethnicityxaxis,yaxis:{level1:ethniclevel1data,level2:ethniclevel2data,level3:ethniclevel3data,level4:ethniclevel4data,level5:ethniclevel5data}}} />
        </div>}
        {racexaxis && raceyaxis && racelevel1data && racelevel2data && racelevel3data && racelevel4data && racelevel5data && <div className="w-full xl:w-12/12 px-4">
        <Race data={{xaxis:racexaxis,yaxis:{level1:racelevel1data,level2:racelevel2data,level3:racelevel3data,level4:racelevel4data,level5:racelevel5data}}}/>
        </div>}
        </div>:<h1 style={{display:"flex",justifyContent:"center",alignItems:"center",flex:1,color:"black",height:80+"vh"}}>There is no data to show here</h1>}
    
    </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
  dashboardData:state.DashboardData
});

export default connect(mapStateToProps)(Dashboard)