import React from "react";
import Chart from "chart.js";

export default function JobsChart(props) {
    console.log(props.data)
  React.useEffect(() => {
    var ctx = document.getElementById("jobschart").getContext('2d');


var myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: props.data.yaxis,
        datasets: [{
            label: 'No of records uploaded', // Name the series
            data: props.data.xaxis, // Specify the data values array
            fill: false,
            borderColor: '#285A84', // Add custom color border (Line)
            backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
            borderWidth: 2 // Specify bar border width
        }]},
    options: {
      responsive: true, // Instruct chart js to respond nicely.
      maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
    }
});
  }, []);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Last 6 Jobs stats
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              UPLOADS
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="jobschart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
