import React, { useState } from 'react'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Modal from 'react-modal';
import { DropDownMenu, MenuItem, Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import host from 'config/host';
import Tour from 'reactour';
import { useSelector } from 'react-redux';
function GenerateApiKey(props) {
    const options = [
       "please select validity", '1 day', '1 week', '1 month', '1 year'
      ];

      const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',

        }
      };
      

      const defaultOption = options[0];

      const hideSnackbar=async()=>{
          setTimeout(()=>{
            showSnackbar(false)
          },1000)
      }

      const RegisterUser = async()=>{
        if(name===""){
            setMessage("Please provide Name");
            showSnackbar(true);
            hideSnackbar()
        }
        else if(isNaN(validity)|| validity==0){
            setMessage("Give valid number of days");
            showSnackbar(true);
            hideSnackbar();
        }
        else{
            setisloading(true);
           setTimeout(()=>{
               setisloading(false);
               setModal(true);
           },500)
            fetch(host.nameserver + '/org/apikeygenerate', {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization':'Bearer '+localStorage.getItem('token')
                },
                body: JSON.stringify({
                    name: name,
                    email : localStorage.getItem('email'),
                    days:validity
                })
              })
                .then((res) => {
                    setisloading(false)
                  if (res.status === 200) return res.json();
                }).then((data)=>{
                    setMessage("api generated successfully");
                    setapikey(data)
                    showSnackbar(true);
                    hideSnackbar()
                    console.log(data)
                }).catch((err)=>{
                    setisloading(false)
                    console.log(err);
                    setMessage("something went wrong");
                    showSnackbar(true);
                    hideSnackbar()
                })
        

        }
      }
      const [name, setName] = useState("")
      const [validity,setValidity] = useState(1);
      const [isloading,setisloading] =useState(false);
      const [message,setMessage]=useState("");
      const [snackbar,showSnackbar]=useState(false);
      const [modal,setModal] = useState(false);
      const [apikey,setapikey] = useState(null);
      const [tourOpen,setTourOpen] = useState(true);
      const [tour,setTour] = useState(false);

      const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })



      const tourConfig = [
        {
          selector: '[data-tut="api-gen"]',
          content: `Why bother downloading the data, when we can push our predictions directly to your database.`
        },
        {
          selector: '[data-tut="api-manage"]',
          content: `To manage the created api keys click here.`
        },
      ]
    return (
        <>
        <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
             <div className="container mx-auto px-4 w-full h-full">
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center w-full h-full" >
          <div className="w-full lg:w-10/12 px-4">
          <LoadingOverlay
      active={isloading}
  spinner
  text='Loading please wait...'
  >
      <Modal
          isOpen={modal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h2 style={{color:'black',fontWeight:'bold'}}>For the security purpose this apikey will not be showed again so save it somewhere</h2>
          <div style={{textAlign:'center',color:'black',marginTop:30}}>Here is the api key</div>
        {apikey && <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <h1 style={{color:'black',fontWeight:'bolder',fontSize:25,marginTop:10,backgroundColor:"#fafafa",borderStyle:'solid',borderColor:'#eeeeee',borderWidth:1,padding:10,borderRadius:5}}>{apikey.apiKey}</h1>
            <i onClick={()=>{navigator.clipboard.writeText(apikey.apiKey); setMessage('copied!'); showSnackbar(true); hideSnackbar()}} className="fas fa-copy" style={{cursor:'pointer',marginLeft:10}}></i>
         </div>}
         {apikey && <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <h1 style={{color:'black',fontWeight:'bolder',fontSize:25,marginTop:10,backgroundColor:"#fafafa",borderStyle:'solid',borderColor:'#eeeeee',borderWidth:1,padding:10,borderRadius:5}}>secret is mailed to your mail id</h1>
            </div>}
         <div style={{display:"flex",justifyContent:'center',alignItems:'center',marginTop:50}}>
         <button onClick={()=>{setModal(false); props.history.push("/api/view");}} style={{backgroundColor:'red',color:'white',fontWeight:'bold',fontSize:20,padding:10,borderRadius:10,paddingLeft:40,paddingRight:40}}>DONE</button>
         </div>
        </Modal>
            <div data-tut="api-gen" className="relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded-lg bg-white border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-600 text-center mb-3 font-bold">
                  <div style={{fontSize:28,fontFamily:"Tenor Sans",borderBottomWidth:1,borderBottomStyle:"solid",borderBottomColor:"#eeeeee",padding:5}}>Genereate API key</div>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-l font-bold mb-1"
                      htmlFor="grid-password"
                    >
                      Name for api key
                    </label>
                    <input
                      type="text"
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Modo.ai"
                      value={name}
                      onChange={(e)=>{setName(e.target.value)}}
                    />
                  </div>
                  <div>
                  <label
                      className="block  text-blueGray-600 text-l font-bold mb-1"
                      htmlFor="grid-password"
                    
                    >
                    Validity for the API key (days)
                    </label>
                    <input
                      type="number"
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="In days"
                      value={validity}
                      onChange={(e)=>{ if(!isNaN(e.target.value)) setValidity(e.target.value); else { setMessage("Number in Days"); showSnackbar(true); hideSnackbar()}}}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-l font-bold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    onClick={()=>{RegisterUser()}}
                    style={{cursor:"pointer",backgroundColor:"#285A84"}}
                    >
                     Generate API key
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-4 relative">
              <div className="w-full text-center mb-3 font-bold">
                <Link to="/api/view" className="text-blueGray-200">
                <button
                data-tut="api-manage"
                     className="bg-blueGray-800 text-white active:bg-blueGray-600 text-xl font-bold capitalise px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                     htmlFor="grid-password"
                     style={{cursor:"pointer",width:180,backgroundColor:"#285A84"}}
                   >
                     Manage keys
                    </button>
                </Link>
              </div>
            </div>
            </LoadingOverlay>
          </div>
          
        </div>
      </div>
        </>
    )
}

export default GenerateApiKey
