const LoginAction  = (email,role,logout)=> async dispatch=>{

    try{
        if(logout){
            dispatch({
                type:'LOGOUT'
            })
        }
        else{
        if(email!=null && email!=undefined){
            dispatch({
                type:'LOGIN',
                payload:{email:email,role:role}
            })
        }
    }
    }catch(err){
        console.log(err)
        dispatch({
            type:"FAIL"
        })
    }

}
export default LoginAction;