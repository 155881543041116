import LoginAction from 'actions/login'
import FooterAdmin from 'components/Footers/FooterAdmin'
import HeaderStats from 'components/Headers/HeaderStats'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Sidebar from 'components/Sidebar/Sidebar'
import React, { useEffect, useState } from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import { Switch, Route, Redirect } from "react-router-dom";
import GenerateApiKey from 'views/manageapis/generateApi'
import ViewApis from 'views/manageapis/viewapis'
import AddUser from 'views/manageuser/addUser'
import PasswordChange from 'views/manageuser/passwordChange'
import ViewUsers from 'views/manageuser/viewUsers'

function ManageApi(props) {
  const [loggedin, setLoggedin] = useState(true);
  const [role,setRole] = useState('owner');
  const dispatch = useDispatch()
  const loginstatus = useSelector(state => state.LoginReducer);
  loginstatus.then(val=>{
    if(val.Loggedin===false){
   if(!localStorage.getItem('loggedin')===true){
    props.history.push('/');
   }
    }
  })
  useEffect(() => {
    setLoggedin(localStorage.getItem('loggedin'));
    setRole(localStorage.getItem('role'));
    if(localStorage.getItem('loggedin'))
     dispatch(LoginAction(localStorage.getItem('email'),localStorage.getItem('role'),false));
  }, [])
    return (
        <>
        <Sidebar />
        <div className="relative md:ml-64 bg-white">
          <AdminNavbar props={props} />
          {/* Header */}
          <div className="relative bg-white md:pt-8 pb-16 pt-12">
              </div>
          <div className="px-4 md:px-10 mx-auto w-full -m-24" style={{height:100+"vh"}}>
           {loggedin ? <Switch>
            {loggedin && <Route path="/api/view" exact component={ViewApis} />}
             {loggedin && <Route path="/api/add" exact component={GenerateApiKey} />}
              <Redirect from="/api" to="/" />
            </Switch>: <h3>Loading</h3> }
            <FooterAdmin />
        </div>
        </div>
      </>
    )
}

const mapStateToProps = state => ({
    loginstate: state.LoginReducer,
  });
  
  export default connect(mapStateToProps)(ManageApi)
