const defaultState={
    jobdata:null
}
const JobDataReducer =async (state=defaultState,action)=>{

    switch(action.type){
        case "JOBDATA": 
        console.log(action.payload.data)
            return {...state,jobdata:action.payload.data}
        case "FAIL":
            return {...state,jobdata:null}
        default:
            return state;
    }

}
export default JobDataReducer;