import ResultsTable from 'components/Cards/ResultsTable'
import { JobResultsHeader } from 'components/Headers/jobResultsHeader'
import React from 'react'
import { connect } from 'react-redux'

export const ResultsView = (props) => {
    return (
        <>
        <div style={{height:80+'vh'}}>
            <ResultsTable color="light"></ResultsTable>
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(ResultsView)
