import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect, useDispatch, useSelector} from 'react-redux'
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import host from "config/host";
import Jobview from "views/jobView/jobview";
import JobHeaderStats from "components/Headers/jobHeaderStats";
import JobDataAction from "actions/jobdata";
import JobErrors from "views/jobView/errors";
import LoginAction from "actions/login";
import LoadingOverlay from "react-loading-overlay";


 function JobLayout(props) {
  const [loggedin, setLoggedin] = useState(true);
  const [role,setRole] = useState('owner');
  const dispatch = useDispatch()
  const loginstatus = useSelector(state => state.LoginReducer);
  loginstatus.then(val=>{
    if(val.Loggedin===false){
   if(!localStorage.getItem('loggedin')===true){
    props.history.push('/');
   }
    }
  })
  useEffect(() => {
    setLoggedin(localStorage.getItem('loggedin'));
    setRole(localStorage.getItem('role'));
    if(localStorage.getItem('loggedin'))
     dispatch(LoginAction(localStorage.getItem('email'),localStorage.getItem('role'),false));
  }, [])
   const [jobid, setJobid] = useState(null)
   useEffect(()=>{
    setJobid(props.location.state.jobid.jobid);
   },[])
   const [loading, setIsLoading] = useState(false)
   
   useEffect(() => {
       if(jobid!=null){
         setIsLoading(true)
      fetch(host.nameserver+'/file/jobdata',{
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization':'Bearer '+localStorage.getItem('token')
        },
        body: JSON.stringify({
            jobid:jobid
        })
      }).then((res)=>{
        setIsLoading(false)
          if(res.status==200)
          return res.json()
      }).then((data)=>{
        dispatch(JobDataAction(data));
      }).catch((err)=>{
        setIsLoading(false);
        console.log(err)
      })
   }
}, [jobid])
   const [loginState, setLoginState] = useState(null)
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar props={props} />
        {/* Header */}
        <JobHeaderStats location={props.history.location.pathname} history={props.history} />
      
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <LoadingOverlay
      active={loading}
  spinner
  text='loading please wait...'
  >
        {loggedin ?
          <Switch>
             {loggedin &&  <Route path="/job/view" exact component={Jobview} />}
             {loggedin &&  <Route path="/job/errors" exact component={JobErrors} />}
          <Redirect from="/job" to="/" />
          </Switch> : <h3>Loading</h3> }
          </LoadingOverlay>
          <FooterAdmin />
          
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
});

export default connect(mapStateToProps)(JobLayout)