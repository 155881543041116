const GuideAction  = (data)=> async dispatch=>{

    try{
      dispatch({
          type:"GUIDE",
          payload:{data:data}
      })
    }catch(err){
        console.log(err)
        dispatch({
            type:"FAIL"
        })
    }

}
export default GuideAction;