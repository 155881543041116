const defaultState={
    data:null
}
const ResultsDataReducer =async (state=defaultState,action)=>{

    switch(action.type){
        case "RESULTSDATA": 
            return {...state,data:action.payload.data}
        case "FAIL":
            return {...state,data:null}
        default:
            return state;
    }

}
export default ResultsDataReducer;