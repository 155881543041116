import React, { useState } from "react";
import {connect} from 'react-redux'
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import GuideSwitch from "views/help/guideswitch";

function Navbar(props) {
  const[email,setEmail] = useState("")
  props.loginstate.then(value=>{

    setEmail(value.email||localStorage.getItem('email'));
  })
  const getName = ()=>{
    if(props.props.location.pathname.includes('dashboard')){
      return "Dashboard"
    }
    else if(props.props.location.pathname.includes('tables')){
      return "Modo Magic"
    }
    else if(props.props.location.pathname.includes('users')){
      return "Manage users"
    }
    else if(props.props.location.pathname.includes('api')){
      return "Manage API"
    }
    else if(props.props.location.pathname.includes('results')){
      return "Predictions"
    }
    else if(props.props.location.pathname.includes('job')){
      return "Jobs summary"
    }
  }
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        {props.props.location.pathname.includes('help')&&<div style={{display:"flex",width:300}}> <GuideSwitch></GuideSwitch></div>}
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-blue text-l uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {getName()}
          </a>
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <p
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-0"
              >{email}</p>
            </div>
          </form>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown props={props.props}  />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
});

export default connect(mapStateToProps)(Navbar)