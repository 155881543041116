import React, { useState } from 'react'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DropDownMenu, MenuItem, Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import host from 'config/host';
import Tour from 'reactour';
import { useSelector } from 'react-redux';
function AddUser() {
    const options = [
       "Please select role", 'admin', 'viewer'
      ];
      const defaultOption = options[0];

      const hideSnackbar=async()=>{
          setTimeout(()=>{
            showSnackbar(false)
          },500)
      }

      const RegisterUser = async()=>{
        if(email===""){
            setMessage("Please provide email");
            showSnackbar(true);
            hideSnackbar()
        }
        else if(role==="please select role"){
            setMessage("Please select the role");
            showSnackbar(true);
            hideSnackbar();
        }
        else{
            setisloading(true);
            fetch(host.nameserver + '/org/addUser', {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization':'Bearer '+localStorage.getItem('token')
                },
                body: JSON.stringify({
                    userEmail : email,
                    userRole: role
                })
              })
                .then((res) => {
                    setisloading(false)
                  if (res.status === 200) return res.json();
                }).then((data)=>{
                    setMessage("user added successfully");
                    showSnackbar(true);
                    hideSnackbar()
                    setEmail("")
                    setRole("please select role")
                }).catch((err)=>{
                    setisloading(false)
                    console.log(err);
                    setMessage("something went wrong");
                    showSnackbar(true);
                    hideSnackbar()
                })
        

        }
      }
      const [email, setEmail] = useState("")
      const [role,setRole] = useState("please select role");
      const [isloading,setisloading] =useState(false);
      const [message,setMessage]=useState("");
      const [snackbar,showSnackbar]=useState(false);
      const [tourOpen,setTourOpen] = useState(true);
      const [tour,setTour]=useState(false)
      const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

      const tourConfig = [
        {
          selector: '[data-tut="add-user"]',
          content: `It's a lot more fun to work in teams.
          Invite your colleagues and assign roles.`
        },
        {
          selector: '[data-tut="manage-users"]',
          content: `Checkout your team when you click here.`
        }
      ]
    return (
        <>
        <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          disableFocusLock
          accentColor={"#5cb7b7"}
        />
             <div className="container mx-auto px-4 w-full h-full">
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center w-full h-full">
          <div className="w-full lg:w-10/12 px-4">
          <LoadingOverlay
      active={isloading}
  spinner
  text='Loading please wait...'
  >
            <div data-tut="add-user" className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-blueGray-400 border-0" style={{backgroundColor:'white',width:100+"%"}}>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-600 text-center mb-3 font-bold">
                  <div style={{fontSize:28,fontFamily:"Tenor Sans",borderBottomWidth:1,borderBottomStyle:"solid",borderBottomColor:"#eeeeee",padding:5}}>Add new user</div>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block capitalise text-blueGray-600 text-l font-bold mb-1"
                      htmlFor="grid-password"
                    >
                      User email
                    </label>
                    <input
                      type="text"
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="useremail@gmail.com"
                      value={email}
                      onChange={(e)=>{setEmail(e.target.value)}}
                    />
                  </div>
                  <div>
                  <label
                      className="block capitalise text-blueGray-600 text-l font-bold mb-1"
                      htmlFor="grid-password"
                    
                    >
                     Select plan
                    </label>
                    <MuiThemeProvider>
       
                    <Dropdown options={options} value={defaultOption} onChange={(e)=>{setRole(e.value)}} placeholder="Select an option" />
        </MuiThemeProvider>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-xl font-bold capitalise px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    onClick={()=>{RegisterUser()}}
                    style={{backgroundColor:"#285A84"}}
                    >
                      Add 
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-4 relative">
              <div className="w-full text-center mb-3 font-bold">
                <Link to="/users/view" className="text-blueGray-200">
                <button
                      data-tut="manage-users"
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-xl font-bold capitalise px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      htmlFor="grid-password"
                      style={{cursor:"pointer",width:180,backgroundColor:"#285A84"}}
                    >
                     Manage  users
                    </button>
                </Link>
              </div>
            </div>
            </LoadingOverlay>
          </div>
          
        </div>
      </div>
        </>
    )
}

export default AddUser
