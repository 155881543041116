import React, { useState } from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useSelector,useDispatch,connect } from 'react-redux';
import job from "layouts/job";

function JobHeaderStats(props) {
  const[jobdata,setJobdata]=useState(null)
props.jobdata.then(value=>{
  if(value.jobdata!=null)
  setJobdata(value);
})
const [currentLevel,setCurrentLevel]=useState("LEVEL 5")
  return (
    <>
      {/* Header */}
     <div className="relative bg-white md:pt-32 pb-4 pt-12">
     <div className="px-4 md:px-10 mx-auto w-full">
     {jobdata ? <div>
          {/* Card stats */}
          <div className="flex flex-wrap" style={{alignItems:'center',display:'flex',justifyContent:'space-evenly',}}>
            <div onClick={()=>{ props.history.push('/job/view') }} className="w-full lg:w-12/12 xl:w-6/12 px-4 ">
              <CardStats
                statSubtitle="JOB STATUS"
                statTitle={jobdata.jobdata.Job.state.toUpperCase()}
              />
            </div>  
            <div onClick={()=>{props.history.push('/job/errors')}} className="w-full lg:w-12/12 xl:w-6/12 px-4">
              <CardStats
                statSubtitle="ERRORS"
                statTitle={jobdata.jobdata.Job.error ? jobdata.jobdata.Job.error:0}
                statIconColor="bg-emerald-500"
              />
            </div> 
            </div>
            </div>:<h3 style={{textAlign:'center'}}>Loading please wait...</h3>}
            </div>
       </div>
    </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
  jobdata:state.JobDataReducer
});

export default connect(mapStateToProps)(JobHeaderStats)