import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import LoadingOverlay from 'react-loading-overlay';
import { Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { useSelector,useDispatch } from 'react-redux';
import LoginAction from "actions/login";
import host from "config/host";

export default function PasswordReset(props) {
  const state = useSelector(state => state.LoginReducer)
  state.then(value=>{
    if(value.Loggedin==true){
    if(value.role==="owner"){
      props.history.push("/owner/register")
    }
    else {
      props.history.push("/Admin/Dashboard")
    }
  }
  })
const dispatch = useDispatch();
  const firebaseConfig = {
    apiKey: "AIzaSyB4O4SCNbTPQR_LOSuEnv5F0D9ExtMED-8",
    authDomain: "modo-staging.firebaseapp.com",
    projectId: "modo-staging",
    storageBucket: "modo-staging.appspot.com",
    messagingSenderId: "182153550136",
    appId: "1:182153550136:web:32c0d0278eaf74b10d09cd",
    measurementId: "G-D5TX1BCW6Y"
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app(); // if already initialized, use that one
 }

 const LoginWithToken=async(token)=>{
  await fetch(host.nameserver + '/user/auth/signin', {
    method: 'POST',
    body: JSON.stringify({firebasetoken:token}),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((res) => {
      setIsLoading(true);
      if (res.status === 200) return res.json();
    })
    .then(async(data) => {
    await dispatch(LoginAction(data.user.email,data.user.role,false));
  await localStorage.setItem('loggedin',true);
  await localStorage.setItem('token',data.token);
  await localStorage.setItem('role',data.user.role);
  await localStorage.setItem('email',data.user.email);
    setSnackBarMessage("Sign in success")
  showSnackbar(true);
  setTimeout(async()=>{
    showSnackbar(false);
  },1000)
    })
    .catch((err) => {
      setIsLoading(true);
setSnackBarMessage("Sign in failed")
  showSnackbar(true);
  setTimeout(async()=>{
    showSnackbar(false);
   
  },1000)
    });
  
 }

 const auth = firebase.auth();
 
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("")
 const [snackbar,showSnackbar] = useState(false)
 const [message,setSnackBarMessage]=useState("")
 const [isLoading,setIsLoading] = useState(false);
 const reset = async()=>{
  setIsLoading(true);
 
  await fetch(host.nameserver + '/user/auth/passwordreset', {
   method: 'POST',
   body: JSON.stringify({email:email}),
   headers: {
     'Content-type': 'application/json; charset=UTF-8',
   },
 })
   .then((res) => {
     setIsLoading(false)
     if (res.status === 200) return res.json();
   })
   .then(async(data) => {
     setSnackBarMessage("Password reset email request sent, check your mail");
     showSnackbar(true);
     setTimeout(()=>{
       showSnackbar(false);
     },2000)
   })
   .catch((err) => {
     setIsLoading(false)
     setSnackBarMessage("something went wrong");
     showSnackbar(true);
     setTimeout(()=>{
       showSnackbar(false);
     },2000)
   });


 setIsLoading(false)
}
  return (
    <>
      <div className="container mx-auto px-4 h-full">
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
          <LoadingOverlay
      active={isLoading}
  spinner
  text='Loading please wait...'
  >
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Enter your email</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(email)=>{setEmail(email.target.value)}}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={()=>{reset()}}
                    >
                     Send password reset link
                    </button>
                  </div>
                </form>
              </div>
            </div>
            </LoadingOverlay>
          </div>
          
        </div>
      </div>
    </>
  );
}
