import GuideAction from 'actions/guide';
import host from 'config/host';
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';

function ViewUsers() {
    const [users, setUsers] = useState(null);
    const [loading,setLoading]= useState(true);
    const dispatch = useDispatch()
    console.log(users)
    useEffect(() => {
        getUsers()
    
    }, [])

    const getUsers=()=>{
      setLoading(true);
        fetch(host.nameserver + '/org/getUsers', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'Authorization':'Bearer '+localStorage.getItem('token')
            },
          })
            .then((res) => {
              if (res.status === 200) return res.json();
            }).then((data)=>{
                setUsers(data);
                setLoading(false);
            }).catch((err)=>{
                console.log(err);
                setLoading(false);
            })
    }
    const [tourOpen,setTourOpen]=useState(true);
    const [tour,setTour]=useState(false)
    const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

  const DeactivateUser=async(email)=>{
    setLoading(true);
    await fetch(host.nameserver + '/user/auth/deactivateuser', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization':'Bearer '+localStorage.getItem('token')
      },
      body:JSON.stringify({
        email:email
      })
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) return res.json();
      }).then((data)=>{
         console.log(data)
         getUsers();
      }).catch((err)=>{
        setLoading(false);
          console.log(err);
      })
  }


    const [dataAvailable, setDataAvailable] = useState(false);
    const tourConfig = [
      {
        selector: '[data-tut="api-keys"]',
        content: `Click here to explore Manage API keys`
      },
    ]
    return (
        <>
        <LoadingOverlay
         active={loading}
     spinner
     text='Loading please wait...'
     >
        <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
        {users && users.length>0 ?  <div className="block w-full overflow-x-auto" style={{height:100+"vh",justifyContent:"center",display:"flex",alignItems:"flex-start",marginTop:120}}>
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16}}
              >
               User email
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16}}
              >
               User role
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                 "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{fontSize:16}}
              >
               Last active
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                 "bg-lightBlue-800 text-white border-lightBlue-700"
                }
                style={{paddingLeft:16,fontSize:16}}
              >
               Change status
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((item)=>{
              return(
              <tr style={{cursor:item['Status']=='complete' ? "pointer":"default"}}>
              <th className="border-t-0 px-6  border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" style={{paddingLeft:15,fontSize:15,fontWeight:'inherit'}}>
                <span
                  className={
                    "ml-3  " +
                    + "text-white"
                  }
                >
               {item["email"]} 
                </span>
              </th>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{paddingLeft:25,fontSize:15}}>
              {item["role"]}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{fontSize:15}}>
                <div className="flex">
             {item['lastActiveAt'] === undefined ? 'No Login Date': new Date(item["lastActiveAt"]).toLocaleString('en-us')}
                </div> 
              </td>
              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2">
              {item['isActive'] ? <button onClick={()=>{DeactivateUser(item["email"]);}} style={{padding:5,color:'#7f0000',borderRadius:5,width:80,fontSize:15,paddingLeft:0}}>
                    Deactivate
             </button>:<button onClick={()=>{DeactivateUser(item["email"]);}} style={{padding:5,color:'#7f0000',borderRadius:5,width:80,fontSize:15,paddingLeft:0}}>
                    Activate
             </button>}    
              </td>
            </tr>)
            })}
          </tbody>
        </table>
      </div>:<h1 style={{justifyContent:"center",alignItems:"center",display:"flex",height:100+"vh"}}>{loading?"Loading please wait..":"No data to show"}</h1>}
      </LoadingOverlay>  
      </>
    )
}

export default ViewUsers
