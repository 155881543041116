import React, { useState,useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import Dropdown from 'react-dropdown';

import { Switch, Route, Redirect } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useSelector,useDispatch,connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ab2str from 'arraybuffer-to-string';
import Modal from "react-modal";
// components
import styled from 'styled-components'
import {FaCross, FaWindowClose} from "react-icons/fa"
import CardTable from "components/Cards/CardTable.js";
import DataAction from "actions/data";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import fileData from "./fileData";
import host from "config/host";
import { Snackbar } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import Tour from "reactour";

function FetchData(props) {
 
  var filescounter = 0;
    const options = [
        {label:'Drag and drop files',
    value:1},  {label:'API data source',
    value:2}
      ];
      const MODAL_STYLES = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          overflow: 'visible',
          padding: 0,
          width: '30%',
          maxWidth: '45rem',
          // all this stuff below is just to position the modal
          transform: 'translateX(-25%)',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          top:'50%',
          textAlign:'center',
          padding:20
        },
      }
      
      const ModalContent = styled.div`
        overflow-y: auto;
        max-height: 50vh;
        padding: 1rem;
      `
      


      const defaultOption = options[0];
      const defaultFileName = "Please select the file type"
  props.data.then(value=>{
    setData(value.data)
    setFile(true)
  })
  const [isLoading, setIsLoading] = useState(false)
  const [noOfFile,setNoOfFils]=useState(-1)
  const [filenames,setFileNames] = useState([])
  const [tourOpen,setTourOpen]=useState(true);
  const [nameModal,setNameModal]=useState(false);
  const [currentFileName,setCurrentFileName]=useState("");
  const [currentFileUploaded,setCurrentFileUploaded]=useState(null);
  const [allfiles,setAllFiles] = useState([])
  const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })


  useEffect(() => {
    console.log('getting all data associated with org')
     fetch(host.nameserver + '/file/api', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('token'),
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then(async(data) => {
            setapiData(data.apiList);
            console.log(data.apiList);
        })
        .catch((err) => {
            console.log(err)
        });
  }, [])

  useEffect(() => {
    setIsLoading(true)
    console.log('getting all data associated with org')
     fetch(host.nameserver + '/file/getfilereq', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('token'),
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) return res.json();
        })
        .then(async(data) => {
           setNoOfFils(data.no)
           setFileNames(data.filenames);

        })
        .catch((err) => {
          setIsLoading(false);
            console.log(err)
        });
  }, [])

  const [apidata,setapiData]= useState(null);
  const [source,setSource] = useState(1);
  const [apiSource,setapiSource]=useState("");
  const [apiKey,setApiKey] = useState("");
  const [secret,setSecret] = useState("");
  const [snackbar,showSnackbar] = useState(false);
  const [message,setMessage] = useState("")
  const [showingApiSourceTable,setShowingApiSourceTable]=useState(false);
  const dispatch = useDispatch();
  const [file,setFile] = useState(false);
  const [data,setData]=useState(null);
  const [uploadedfiles,setUploadedFiles] = useState(0);
  const [applications,setApplications] = useState(false);
  const [workex,setWorkex] = useState(false);
  const [tour,setTour] = useState(false);
  const tourConfig = [
    {
      selector: '[data-tut="drag"]',
      content: `Drag and drop the applicants to be evaluated(upload the two files that we emailed you after signup ).`
    }
  ]


  const removeFile = async(fileName)=>{

    let Files = allfiles;
    Files = Files.filter((file) => file.name !== fileName);
    await setAllFiles(Files)

  }


  const uploadFiles = async()=>{
    setTourOpen(false);
    var totalfiles=0
    var filedata = new FormData()
    const filespromise = allfiles.map((fileitem)=>{
      filedata.append(fileitem.name,fileitem.file);
    }) 
   Promise.all(filespromise);
  await filedata.forEach((file)=>{
   totalfiles++;
  })
  console.log(totalfiles,noOfFile)
    if(totalfiles==noOfFile){
    setIsLoading(true);
    await fetch(host.nameserver+'/file/upload', {
     method: 'POST',
     headers:{
         'Authorization':'Bearer '+localStorage.getItem('token')
     },
     body: filedata
   }).then((res)=>{
     setIsLoading(false)
       if(res.status==200){
           setMessage("upload success");
           showSnackbar(true);
           hideSnackbar();
           setAllFiles([])
           setUploadedFiles(0)
           return res.json()
       }
   }).then((data)=>{
     setUploadedFiles(0)
     setMessage("upload success");
       showSnackbar(true);
       hideSnackbar();
      props.history.push('/admin/tables')
   
   }).catch((err)=>{
     setUploadedFiles(0);
     setIsLoading(false)
       setMessage("upload failed");
       showSnackbar(true);
       hideSnackbar();
       console.log(err)
   })
  }
  }

  const updateFile=async ()=>{
    // filedata.append(currentFileName, currentFileUploaded)
    let File = currentFileUploaded;
    console.log(File);
    await setAllFiles([...allfiles,{name:currentFileName,file:currentFileUploaded}])
    await setUploadedFiles(uploadedfiles+1);
    await setCurrentFileUploaded(null)
    await setCurrentFileName("")
    await setNameModal(false);
   
    // uploadFiles()
    
    
  }
  
  useEffect(() => {
  if(allfiles.length==noOfFile){
    uploadFiles()
  }
  }, [uploadedfiles])

  const onDrop = useCallback(async (acceptedFiles) => {
      let currentFile = acceptedFiles[0];
      setCurrentFileUploaded(currentFile);
     
  //     const names = currentFile.name.split('.');
  // // filedata.append(names[0], file)
  // console.log(names[0]);
  window.scrollTo(0,document.body.scrollHeight);
  setNameModal(true);

    //   const fileReader = new FileReader();
    //   fileReader.readAsArrayBuffer(file);

    //   fileReader.onload = async (e) => {
    //     const bufferArray = e.target.result;

    //     const wb = XLSX.read(bufferArray, { type: "buffer" });

    //     const wsname = wb.SheetNames[0];

    //     const ws = wb.Sheets[wsname];

    //     const data = XLSX.utils.sheet_to_json(ws);

    //     setData(data);
    //     await dispatch(DataAction(data))
    //     setFile(true)
    //     props.history.push("/admin/tables")
    //   };

    //   fileReader.onerror = (error) => {
    //     console.log(error);
    //   }
    
  }, [])

  const hideSnackbar = async()=>{
      setTimeout(()=>{
        showSnackbar(false);
      },500)
  }

  const registerSource = async()=>{
      if(apiSource==""){
          setMessage("Please give source");
          showSnackbar(true);
          hideSnackbar();
      }
      else if(apiKey==""){
          setMessage("Please give API Key");
          showSnackbar(true);
          hideSnackbar();
      }
      else if(secret==""){
          setMessage("Please give secret");
          showSnackbar(true);
          hideSnackbar();
      }
      else{
        setIsLoading(true);
        await fetch(host.nameserver + '/file/api', {
            method: 'POST',
            body: JSON.stringify({
                apiSource : apiSource,
                key: apiKey,
                secret: secret
            }),
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('token'),
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
            .then((res) => {
              setIsLoading(false)
              if (res.status === 200) return res.json();
            })
            .then(async(data) => {
                setSecret("")
                setApiKey("")
                setapiSource("")
                console.log(data)
                setMessage("Successfully Added");
                showSnackbar(true);
                hideSnackbar();
            })
            .catch((err) => {
              setIsLoading(false)
                console.log(err)
                setMessage("Failed please try again");
                showSnackbar(true);
                hideSnackbar();
            });
      }
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop,multiple:true})

  const showDragDrop = ()=>{
      return(
        <div data-tut="drag" className="flex flex-wrap mt-4 z-50">
        <div  style={{width:100+"%",justifyContent:"center",alignItems:"center",textAlign:"center"}} {...getRootProps()}>
          <input {...getInputProps()}  />
          <p style={{padding:20,borderStyle:"solid",borderWidth:1,borderColor:"#bdbdbd",height:50+"vh",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"#f5f5f5"}}>{uploadedfiles==0? "Drag and drop files one by one here":"Drag and drop files one by one here"}</p>
        </div>
        </div>
      
      )
  }
  const showApi = ()=>{
      return(
        <div className="relative flex flex-col min-w-0 break-words  w-full mb-6   shadow-lg rounded-lg bg-blueGray-150 border-0" style={{marginTop:20}}>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div className="text-blueGray-400 text-center mb-3 font-bold">
            <h2>Add New Data Source</h2>
          </div>
         {!showingApiSourceTable ? <form>
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                API SOURCE
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="provide api source here"
                value={apiSource}
                onChange={(e)=>{setapiSource(e.target.value)}}
              />
            </div>

            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              
              >
                API KEY
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="provide api key here"
                value={apiKey}
                onChange={(e)=>{setApiKey(e.target.value)}}
              />
            </div>
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                  
              >
                SECRET
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="provide api secret here"
                value={secret}
                onChange={(e)=>{setSecret(e.target.value)}}
              />
            </div>

            <div className="text-center mt-6">
              <button
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
                onClick={()=>{registerSource()}}
              >
                ADD DATA SOURCE
              </button>
            </div>
         </form> :
        //  table to show data
        <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  ('light' === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
               API SOURCE
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  ('light' === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
               API KEY
              </th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                  ('light' === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
               API SECRET
              </th>
            </tr>
          </thead>
          <tbody>
            {apidata.map((item)=>{
              return(
              <tr style={{cursor:item['Status']=='complete' ? "pointer":"default"}}>
              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <span
                  className={
                    "ml-3 font-bold " +
                    +('light' === "light" ? "text-blueGray-600" : "text-white")
                  }
                >
               {item["source"]} 
                </span>
              </th>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {item["key"]}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <div className="flex">
             {item["secret"]}
                </div> 
              </td>
            </tr>)
            })}
          </tbody>
        </table>
      </div>
          }
          <div className="flex flex-wrap mt-4 relative">
              <div className="w-full text-center mb-3 font-bold" style={{cursor:"pointer"}} onClick={()=>{setShowingApiSourceTable(!showingApiSourceTable)}}>
                  <small>{!showingApiSourceTable ? 'SEE ALL DATA SOURCES': 'ADD DATA SOURCE'}</small>
              </div>
              </div>
        </div>
      </div>
      )
  }

  return (
    <>
     <LoadingOverlay
      active={isLoading}
  spinner
  text='loading please wait...'
  >
      <div style={{marginTop:100}} >
      <Tour
          onRequestClose={()=>{localStorage.setItem('tour',false); setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:0}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
                    <label
                      className="block capitalise text-blueGray-600 text-xl py-5 text-center font-bold mb-2"
                      htmlFor="grid-password"
                    
                    >
                     Select data source
                    </label>
                    <MuiThemeProvider>
                    <Dropdown options={options} value={defaultOption}  onChange={(e)=>{setSource(e.value)}}  placeholder="Select an option" />
        </MuiThemeProvider>
                  </div>
                  <div className="flex flex-wrap">
                    {allfiles.length>0 && allfiles.map((file)=>{
 return (<div key={file.name} onClick={()=>{removeFile(file.name)}} style={{cursor:"pointer", borderRadius:10,display:"flex",height:25,padding:10,borderStyle:"solid",borderWidth:1,marginTop:20,alignItems:"center",justifyContent:"center",backgroundColor:"white"}}>
 {file.name} <FaWindowClose color={"red"} style={{borderRadius:20,paddingLeft:2,}}></FaWindowClose>
</div>)
                    })}
               
                </div>
 {source==2? showApi():showDragDrop()}
   </LoadingOverlay>
   <Modal isOpen={nameModal}   onRequestClose={()=>setNameModal(false)} style={MODAL_STYLES}>
        <h3 style={{fontSize:20,fontWeight:"bold",fontFamily:"Montserrat SemiBold"}}>Choose file type</h3>
        <div style={{width:100+"%",height:"auto",flexDirection:"column",display:"flex",justifyContent:"center",padding:15,height:"auto",zIndex:10}}>
        <MuiThemeProvider>
        <Dropdown options={filenames} value={defaultFileName}  onChange={(e)=>{setCurrentFileName(e.value)}}  placeholder="Select an option" />
       </MuiThemeProvider>
       <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 mt-5"
                      type="button"
                     onClick={()=>{
                        updateFile();
                     }}
                    >
                      Submit Name
                    </button>
        </div>
      </Modal>
  </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
});

export default connect(mapStateToProps)(FetchData)