const defaultState={
    guidestate:false
}
const GuideReducer =async (state=defaultState,action)=>{

    switch(action.type){
        case "GUIDE": 
        console.log(action.payload.data)
            return {...state,guidestate:action.payload.data}
        case "FAIL":
            return {...state,jobdata:false}
        default:
            return state;
    }

}
export default GuideReducer;