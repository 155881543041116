import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import Dashboard from "views/admin/Dashboard";
import Owner  from "./layouts/owner"
import {Provider} from 'react-redux'
import Store from "./store";
import { useSelector } from 'react-redux';
import ManageUsers from 'layouts/manageUsers';
import manageApi from 'layouts/manageApi';
import Jobview from 'views/jobView/jobview';
import JobLayout from 'layouts/job'
import ResultsLayout from 'layouts/Results';
import HelpLayout from './layouts/help'


function App() {
    return (
            <BrowserRouter>
  <Provider store={Store}>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/owner" component={Owner} />
      <Route path="/users" component={ManageUsers} />
      <Route path="/api" component={manageApi} />
      <Route path="/results" component={ResultsLayout} />
      <Route path="/job" component={JobLayout} />
      <Route path="/help" component={HelpLayout} />
      <Route path="/" exact component={Auth} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
    </Provider>
  </BrowserRouter>
    )
}

export default App;
