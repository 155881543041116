import React, { useState } from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useSelector,useDispatch,connect } from 'react-redux';

function OwnerHeader(props) {
  console.log(props.location)
  const[data,setData]=useState(null)
props.data.then(value=>{
  setData(value.data)
})
  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-18 pb-15 pt-11" style={{width:100+"%",marginBottom:50}}>
      <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="GPA 3 - 4"
                  statTitle="3500"
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="GPA 3 - 4"
                  statTitle="3500"
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-emerald-500"
                />
              </div>

              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                 statSubtitle="GPA 2 - 3"
                 statTitle="4000"
                 statArrow="down"
                 statPercent="3.48"
                 statPercentColor="text-red-500"
                 statDescripiron="Since last week"
                 statIconName="fas fa-chart-pie"
                 statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                   statSubtitle="GPA less than 2"
                   statTitle="2500"
                   statArrow="down"
                   statPercent="3.48"
                   statPercentColor="text-red-500"
                   statDescripiron="Since last week"
                   statIconName="fas fa-chart-pie"
                   statIconColor="bg-red-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
});

export default connect(mapStateToProps)(OwnerHeader)