import React, { useEffect, useState } from 'react'
import { FormControlLabel, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import GuideAction from 'actions/guide';
import host from 'config/host';
function GuideSwitch() {
    const dispatch = useDispatch();
    const [tour,setTour]=useState(false);
    const state = useSelector(state => state.GuideReducer)
  state.then(value=>{
   setTour(value.guidestate)
  })

  const DisableGuide=async(flag)=>{
    await dispatch(GuideAction(flag))
    await fetch(host.nameserver + '/user/auth/disableguide', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization':'Bearer '+localStorage.getItem('token')
      },
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      }).then((data)=>{
         console.log(data)
      }).catch((err)=>{
          console.log(err);
      })



  }

   
    return (
              <div style={{display:"flex", padding:15, borderStyle:"solid",borderWidth:1,borderColor:"#eeeeee",color:"black",justifyContent:'center',alignItems:"center"}}> 
            <FormControlLabel
        control={
          <Switch
            checked={tour}
            onChange={(e)=>{  DisableGuide(e.target.checked)}}
            value={tour}
          />
        }
        label={"Enable guide tour"}
        labelPlacement="start"
        aria-label
      />
      </div>
    )
}

export default GuideSwitch
