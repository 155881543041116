import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import LoadingOverlay from 'react-loading-overlay';
import { Snackbar } from "material-ui";
import Alert from 'react-bootstrap/Alert'
import { useSelector,useDispatch } from 'react-redux';
import LoginAction from "actions/login";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import host from "config/host";
import logo from './../../assets/img/modo.png'
import Tour from "reactour";
import GuideAction from "actions/guide";
import { Link } from "react-router-dom";
import './styles.css'
import { FaEye } from "react-icons/fa";
export default function Register(props) {
  const firebaseConfig =  {
    apiKey: "AIzaSyB4O4SCNbTPQR_LOSuEnv5F0D9ExtMED-8",
    authDomain: "modo-staging.firebaseapp.com",
    projectId: "modo-staging",
    storageBucket: "modo-staging.appspot.com",
    messagingSenderId: "182153550136",
    appId: "1:182153550136:web:32c0d0278eaf74b10d09cd",
    measurementId: "G-D5TX1BCW6Y"
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app(); // if already initialized, use that one
 }
 const state = useSelector(state => state.LoginReducer)
 state.then(value=>{
   if(value.Loggedin==true){
   if(value.role==="owner"){
     props.history.push("/owner/register")
   }
   else {
     props.history.push("/admin/dashboard")
   }
 }
 })

 const guidestate = useSelector(state => state.GuideReducer)
 guidestate.then(value=>{
   setTourOpen(value.guidestate)
 })
const dispatch = useDispatch();
   const auth = firebase.auth();
   const [email, setEmail] = useState(null);
   const [password,setPassword] = useState(null);
   const [isLoading,setIsLoading] = useState(false);
   const [snackbar,showSnackbar] = useState(false);
   const [message,setSnackbarMessage] = useState("");
   const [agreement,setAgreement] = useState(false);
   const [tourOpen,setTourOpen] = useState(true);
   const [passwordShow,setPasswordShow] = useState(false);
    const [dialog,setDialog]=useState(true);
    const [resetpasswordshow,setResetPasswordShow] = useState(false);
   const Signin = async(token)=>{
    await fetch(host.nameserver + '/user/auth/signin', {
      method: 'POST',
      body: JSON.stringify({firebasetoken:token}),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) return res.json();
      })
      .then(async(data) => {
        
      await dispatch(LoginAction(email,data.user.role,false));
      if(data.user.guidetour==undefined)
      await dispatch(GuideAction(true));
      else
      await dispatch(GuideAction(data.user.guidetour));
    await localStorage.setItem('loggedin',true);
    await localStorage.setItem('token',data.token);
    await localStorage.setItem('role',data.user.role);
    await localStorage.setItem('email',email);
    await localStorage.setItem('tour',false);
      setSnackbarMessage("Sign up success")
    showSnackbar(true);
    setTimeout(async()=>{
      showSnackbar(false);
    },1000)
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
  setSnackbarMessage("Sign in failed")
    showSnackbar(true);
    setTimeout(async()=>{
      showSnackbar(false);
     
    },1000)
      });
   }

   const disableTour=()=>{
     dispatch(GuideAction(false));
     setDialog(false);
   }

   const SignUp = async()=>{
    try{
      if(!validateEmail(email)){
        setSnackbarMessage("Please provide valid email!")
      showSnackbar(true);
      setTimeout(()=>{
        showSnackbar(false);
      },1000)
      }
      else if(password.length<8){
        setSnackbarMessage("password should be atleast 8 characters")
        showSnackbar(true);
        setTimeout(()=>{
          showSnackbar(false);
        },1000)
      }
      else if(agreement==false){
        setSnackbarMessage("Please agree to privacy policy")
        showSnackbar(true);
        setTimeout(()=>{
          showSnackbar(false);
        },1000)
      }
      else{
      setIsLoading(true)
      const response = await auth.createUserWithEmailAndPassword(email, password);
      console.log(response)
      //auth/email-already-in-use
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        // Send token to your backend via HTTPS
        // ...
        Signin(idToken);
      }).catch(function(error) {
        // Handle error
        setIsLoading(false);
        setSnackbarMessage('Couldnot create an account');
        showSnackbar(true);
        setTimeout(()=>{
          showSnackbar(false);
        },1000)
      });
    }
    }
    catch(error){
      console.log(error)
      setIsLoading(false);
localStorage.clear(); 
sessionStorage.clear();  
auth.signOut(); 
dispatch(LoginAction("","",true));
     setSnackbarMessage('Couldnot create an account');
     showSnackbar(true);
     setTimeout(()=>{
       showSnackbar(false);
       if(error.code=="auth/email-already-in-use")
       setResetPasswordShow(true);
     },1000)
    }
   }
   function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  const tourConfig = [
    {
      selector: '[data-tut="signup"]',
      content: `Create your secure modo.ai account. Your account security is backed by Google Firebase.`
    }
  ]

  const resetPasswordConfig = [
    {
      selector: '[data-tut="signup"]',
      content: () => (
        <div>
        Seems like you already have an account, do you want to reset password?
           <div>
            <button
              color="dark"
              style={{cursor:"pointer", textDecoration:"underline",color:'blue'}}
              onClick={()=>{props.history.push("/auth/passwordreset")}}
            >
             {" "} click here
            </button>{" "}
            </div>
        </div>
      ),
      style: {
        backgroundColor: "black",
        color: "white"
      }
    },
  ]

  const togglePasswordView = ()=>{
    setPasswordShow(!passwordShow)
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
      <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={false}
          maskClassName="mask"
          className="helper"
          rounded={5}
          disableFocusLock
          disableInteraction={false}
          accentColor={"#5cb7b7"}
        />

<Tour
          onRequestClose={()=>{setResetPasswordShow(false)}}
          steps={resetPasswordConfig}
          isOpen={resetpasswordshow}
          maskClassName="mask"
          className="helper"
          rounded={5}
          disableFocusLock
          disableInteraction={false}
          accentColor={"#5cb7b7"}
        />

      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
          <div data-tut="signup" className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-400 border-0" style={{backgroundColor:'white'}}>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-600 text-center mb-3 font-bold">
                  <div style={{fontSize:25,fontFamily:"Tenor Sans",borderBottomWidth:1,borderBottomStyle:"solid",borderBottomColor:"#eeeeee",padding:5}}>Sign up</div>
                </div>
                <LoadingOverlay
      active={isLoading}
  spinner
  text='Loading please wait...'
  >
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block capitalise text-blueGray-600 text-l font-bold mb-0"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(email)=>{setEmail(email.target.value)}}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block capitalise text-blueGray-600 text-l font-bold mb-0"
                      htmlFor="grid-password"
                    >
                     <div style={{flexDirection:"row",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    Password <FaEye size={18} style={{cursor:"pointer"}} onClick={()=>{togglePasswordView()}}></FaEye>
                    </div>
                    </label>
                    <input
                      type={!passwordShow?"password":"text"}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange={(pass)=>{setPassword(pass.target.value)}}
                      value={password}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onClick={(e)=>{setAgreement(!agreement)}}
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          className="text-lightBlue-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      style={{backgroundColor:'#285A84'}}
                      onClick={()=>{SignUp()}}
                    >
                      Create account
                    </button>
                  </div>
                </form>
                </LoadingOverlay>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
            <div className="w-1/2">
              <a
                href="#pablo"
                onClick={(e) => {e.preventDefault(); props.history.push('/auth/passwordreset')} }
                className="text-blue-200"
              >
                <small>Forgot password?</small>
              </a>
            </div>
            <div className="w-1/2 text-right">
              <Link to="/" className="text-blue-200">
                <small>Already have an account?</small>
              </Link>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
