const defaultState={
    email:"",
    role:"",
    Loggedin:false
}
const LoginReducer =async (state=defaultState,action)=>{

    switch(action.type){
        case "LOGIN": 
            return {...state,email:action.payload.email,role:action.payload.role,Loggedin:true}
        case "FAIL":
            return {...state,Loggedin:false}
        case "LOGOUT":
            return {...state,Loggedin:false}
        default:
            console.log("Default")
            return state;
    }

}
export default LoginReducer;