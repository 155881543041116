import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Papa from 'papaparse';
import { DropDownMenu, MenuItem, Snackbar } from "material-ui";
import CSVReader from 'react-csv-reader'
import Modal from "react-modal";
// components
import styled from 'styled-components'
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import host from 'config/host';
import './styles.css'
import { FaWindowClose } from 'react-icons/fa';


function AddCompany() {
  const [authorised,setAuthorised]=useState(false);
  const [filetypes,setfiletypes]=useState([])
  const MODAL_STYLES = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      overflow: 'visible',
      padding: 0,
      width: '30%',
      maxWidth: '45rem',
      // all this stuff below is just to position the modal
      transform: 'translateX(-25%)',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      top:'50%',
      textAlign:'center',
      padding:20
    },
  }
  
  const ModalContent = styled.div`
    overflow-y: auto;
    max-height: 50vh;
    padding: 1rem;
  `

  useEffect(()=>{
    fetch(host.nameserver + '/org/getfiletypes', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization':'Bearer '+localStorage.getItem('token')
      },
    })
      .then((res) => {
     if (res.status === 200) return res.json();
      })
      .then(async(data) => {
        console.log(data)
        let filetypenames = []
        if(data.length>0){
          data.map((filetypename)=>{
            filetypenames.push(filetypename.name);
          })
          setfiletypes(filetypenames)
        }
      }).catch(err=>{
        console.log(err)
      })
  },[])

    const Register = async ()=>{
        if(orgname==""){
          window.scroll(0,0)
            setMessage("Organisation name can't be empty")
            showSnackbar(true);
            setTimeout(async()=>{
              showSnackbar(false);
            },1000)
        }
        else if(ownername==""){
          window.scroll(0,0)
            setMessage("Owner name name can't be empty")
            showSnackbar(true);
            setTimeout(async()=>{
              showSnackbar(false);
            },1000)
        }
        else if(owneremail==""){
          window.scroll(0,0)
            setMessage("owner email can't be empty")
            showSnackbar(true);
            setTimeout(async()=>{
              showSnackbar(false);
            },1000)
        }
        else if(FilesData.length != numberoffiles){
          window.scroll(0,0)
          setMessage("Number of files and uploaded files don't match")
          showSnackbar(true);
          setTimeout(async()=>{
            showSnackbar(false);
          },1000)
        }
        else if(!authorised){
          window.scroll(0,0)
          setMessage("Please authorise yourself first")
          showSnackbar(true);
          setTimeout(async()=>{
            showSnackbar(false);
          },3000)
        }
        else{
            setLoading(true);
            setAuthorised(false);
            await fetch(host.nameserver + '/org/createOrg', {
                method: 'POST',
                body: JSON.stringify({orgname:orgname,owneremail:owneremail,ownername:ownername,billing:billing!=""?billing:options[0],FilesNumber:numberoffiles,FilesData:FilesData}),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization':'Bearer '+localStorage.getItem('token')
                },
              })
                .then((res) => {
               if (res.status === 200) return res.json();
                })
                .then(async(data) => {
                  console.log(data)
                    setLoading(false)
                    window.scroll(0,0)
               setOrgName("")
               setOwnerName("")
               setowneremail("")
               setFilesData([])
               setAuthorised(false)
                setMessage(data.message)
              showSnackbar(true);
              setTimeout(async()=>{
                showSnackbar(false);
              },3000)
                })
                .catch((err) => {
                    setLoading(false)
                  console.log(err);
                  setMessage(err.message)
              showSnackbar(true);
              setTimeout(async()=>{
                showSnackbar(false);
               
              },1000)
                });

        }
    }




    const options = [
        'Free', 'Regular', 'Premium'
      ];
      const defaultOption = options[0];
      const [loading,setLoading] = useState(false);
      const [snackbar,showSnackbar] = useState(false);
      const [file,setFile] = useState(null)
      const [numberoffiles,setNumberOfFiles]=useState(0);
      const [fileName,setFileName]=useState("") 
      const [nameModal,setNameModal] = useState(false);
      const [message,setMessage]=useState("");
      const [orgname,setOrgName] = useState("")
      const [ownername,setOwnerName]=useState("")
      const [owneremail,setowneremail]=useState("")
      const [billing,setBillingPlan]=useState("")
      const [FilesData,setFilesData] = useState([])
      const [otp,setOtp] = useState("123456")
      const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
          header
            .toLowerCase()
            .replace(/\W/g, '_')
      }
    
const updateFilesData = (fileName,Headers)=>{
  const FileObject = {
    name:fileName,
    headers:Headers
  }
  setFilesData([...FilesData,FileObject]);
  console.log(FilesData);
}

      const consolidate=(data)=>{
        const [headerRow] = data;
        console.log(headerRow)
        return headerRow;
        }
            
        const parseHeaders = () => {
          Papa.parse(
            file,
            {
              complete: function (results) {
               updateFilesData(fileName,consolidate(results.data));
              },
            },
          );
          setFile(null);
          setFileName("")
          setNameModal(false);
        }
        
        const removeFile = async(fileName)=>{

          let Files = FilesData;
          Files = Files.filter((file) => file.name !== fileName);
          await setFilesData(Files)
      
        }

    const sendOtp = async()=>{
      setLoading(true);
      await fetch(host.nameserver + '/org/getotp', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((res)=>{
        return res.json()
      }).then((data)=>{
        setLoading(false)
        window.scroll(0,0)
        setMessage("otp sent, otp is valid for 2 minutes")
        showSnackbar(true);
        setTimeout(async()=>{
          showSnackbar(false);
         
        },3000)
      }).catch((err)=>{
        setLoading(false)
        window.scroll(0,0)
        setMessage("otp couldn't be sent")
        showSnackbar(true);
        setTimeout(async()=>{
          showSnackbar(false);
         setAuthorised(false);
        },3000)
      })
    }

    const verifyOtp = async()=>{
      setLoading(true)
      await fetch(host.nameserver + '/org/verifyotp', {
        method: 'POST',
        body:JSON.stringify({otp:otp}),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((res)=>{
        if(res.status==200)
        return res.json()
        else
        throw new Error("Otp failed");
      }).then((data)=>{
        console.log(data)
        setLoading(false)
        window.scroll(0,0)
        setMessage("otp verified successfully")
        showSnackbar(true);
        setAuthorised(true);
        setTimeout(async()=>{
          showSnackbar(false);
         
        },3000)
      }).catch((err)=>{
        setLoading(false)
        setMessage("otp couldn't be verified")
        showSnackbar(true);
        setTimeout(async()=>{
          showSnackbar(false);
         
        },3000)
      })
    }

    return (
        <>
      <div className="container mx-auto px-4 w-full h-full">
      <Modal isOpen={nameModal}   onRequestClose={()=>setNameModal(false)} style={MODAL_STYLES}>
        <h3 style={{fontSize:20,fontWeight:"bold",fontFamily:"Montserrat SemiBold"}}>Choose file type</h3>
        <div style={{width:100+"%",height:"auto",flexDirection:"column",display:"flex",justifyContent:"center",padding:15,height:"auto",zIndex:10}}>
        <MuiThemeProvider>
        <Dropdown options={filetypes} value={"Select file type"}  onChange={(e)=>{setFileName(e.value)}}  placeholder="Select an option" />
       </MuiThemeProvider>
       <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 mt-5"
                      type="button"
                     onClick={()=>{
                      parseHeaders();
                     }}
                    >
                      Submit Name
                    </button>
        </div>
      </Modal>
      <MuiThemeProvider>
              <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                  </Snackbar>
                </MuiThemeProvider>
        <div className="flex content-center items-center justify-center w-full h-full">
          <div className="w-full lg:w-8/12 px-4">
          <LoadingOverlay
      active={loading}
  spinner
  text='Loading please wait...'
  >
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Add New Client</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Organisation Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Icecream Labs"
                      value={orgname}
                        onChange={(orgname)=>{setOrgName(orgname.target.value)}}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    
                    >
                      Admin Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Rama Krishna"
                    value={ownername}
                    onChange={(ownername)=>{setOwnerName(ownername.target.value)}}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                        
                    >
                      Admin Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="username@doamin.com"
                    value={owneremail}
                    onChange={(owneremail)=>{setowneremail(owneremail.target.value)}}
                    />
                  </div>
                  <div>
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    
                    >
                     Select plan:
                    </label>
                    <MuiThemeProvider>
       
                    <Dropdown options={options} value={defaultOption} onChange={(e)=>{setBillingPlan(e.value)}} placeholder="Select an option" />
        </MuiThemeProvider>
                  </div>

                  <div className="relative w-full mb-3 mt-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                        
                    >
                     Number of files
                    </label>
                    <input
                     type="text" pattern="[0-9]*"
                      value={numberoffiles}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(event)=>{if(isNaN(event.target.value)) {
                      setMessage("Only numbers allowed");
                      showSnackbar(true);
                      setTimeout(()=>{
                        showSnackbar(false);
                      },1000)
                    } 
                    else 
                    setNumberOfFiles(event.target.value)}}
                    // parseHeaders(event.target.files[0])
                    />
                  </div>
                  <div className="flex flex-wrap">
                    {FilesData.length>0 && FilesData.map((file)=>{
 return (<div key={file.name} onClick={()=>{removeFile(file.name)}} style={{cursor:"pointer", borderRadius:10,display:"flex",height:25,padding:10,borderStyle:"solid",borderWidth:1,marginTop:20,alignItems:"center",justifyContent:"center",backgroundColor:"white"}}>
 {file.name} <FaWindowClose color={"red"} style={{borderRadius:20,paddingLeft:2,}}></FaWindowClose>
</div>)
                    })}
               
                </div>

                  <div className="relative w-full mb-3 mt-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                        
                    >
                      Upload sample files one by one here
                    </label>
                    <input
                      type="file"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(event)=>{if(event.target.files[0]){ setFile(event.target.files[0]); setNameModal(true);}}}
                    // parseHeaders(event.target.files[0])
                    />
                  </div>

                  <div className="relative w-full mb-3 mt-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                        
                    >
                    OTP 
                    </label>
                    <input
                     type="text" pattern="[0-9]*"
                      value={otp}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-6/12 ease-linear transition-all duration-150"
                    onChange={(event)=>{if(isNaN(event.target.value)) {
                      setMessage("Only numbers allowed");
                      showSnackbar(true);
                      setTimeout(()=>{
                        showSnackbar(false);
                      },1000)
                    } 
                    else 
                    setOtp(event.target.value)}}
                    // parseHeaders(event.target.files[0])
                    />
                     <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 ml-2 mb-1 w-3/12 ease-linear transition-all duration-150"
                      type="button"
                     onClick={()=>{
                         verifyOtp()
                     }}
                     disabled={authorised}
                    >
                     VERIFY OTP
                    </button>
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-4 mb-1 w-4/12 ease-linear transition-all duration-150"
                      type="button"
                     onClick={()=>{
                         sendOtp();
                     }}
                     disabled={authorised}
                    >
                     SEND OTP
                    </button>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                     onClick={()=>{
                         Register();
                     }}
                    >
                      ADD CLIENT
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-4 relative">
              <div className="w-full text-center mb-3 font-bold">
                <Link to="/owner/clients" className="text-blueGray-200">
                  <small>See All Clients</small>
                </Link>
              </div>
            </div>
            </LoadingOverlay>
          </div>

        </div>
      </div>
    </>
    )
}

export default AddCompany
