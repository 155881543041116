const JobDataAction  = (data)=> async dispatch=>{
    try{
        if(data!=null && data!=undefined){
            dispatch({
                type:'JOBDATA',
                payload:{data:data}
            })
        }
    }catch(err){
        console.log(err)
        dispatch({
            type:"FAIL"
        })
    }

}
export default JobDataAction;