import React, { useState } from "react";
import PropTypes from "prop-types";
import download from 'downloadjs';
import {FaDownload} from 'react-icons/fa'

// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";
import host from "config/host";
import LoadingOverlay from "react-loading-overlay";

export default function JobErrorsTable({ color,data,history }) {
  const [isloading,setIsLoading] = useState(false);
  const DownloadFile = async()=>{
    setIsLoading(true);
    try{
    const res = await fetch(host.nameserver+'/file/joberrorsfile',{
      method:'POST',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      'Content-type': 'application/json; charset=UTF-8',
    },
    body:JSON.stringify({
      jobid:data[0].jobId,
      email:localStorage.getItem('email')
    })
    });
   const blob = await res.blob();
   download(blob, "errors.csv");
   setIsLoading(false);
  }catch(err){
    console.log(err)
    setIsLoading(false);
  }
  }
  return (
    <>
    <LoadingOverlay
     active={isloading}
     spinner
     text='Downloading please wait...'
    >
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 mt-12 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
               Error logs
              </h3>
              <button style={{backgroundColor:'#87C1F1',padding:5,color:'white',borderRadius:5,fontWeight:'bold'}} onClick={()=>{DownloadFile()}}>Log file <FaDownload style={{display:'inline',alignItems:'center',justifyContent:'center',paddingLeft:2}}></FaDownload></button>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{paddingLeft:36}}
                >
                  AMCAS
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                 Error details
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item)=>{
                return(
                <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                    style={{paddingLeft:0}}
                  >
                {item.amcas_id}
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
               {item.error}
                </td>
              </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
      </LoadingOverlay>
    </>
  );
}

JobErrorsTable.defaultProps = {
  color: "light",
};

JobErrorsTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
