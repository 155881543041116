import React from "react";
import { createPopper } from "@popperjs/core";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch } from "react-redux";
import LoginAction from "actions/login";

const UserDropdown = (props) => {
const dispatch = useDispatch();
  // dropdown props
  const firebaseConfig = {
    apiKey: "AIzaSyDL7jbqmdZoGtFbpeMQv3y21PoUuj3-XdM",
    authDomain: "icecreamlabs-49377.firebaseapp.com",
    projectId: "icecreamlabs-49377",
    storageBucket: "icecreamlabs-49377.appspot.com",
    messagingSenderId: "833535691368",
    appId: "1:833535691368:web:43c6c1d3ac2590923809dd",
    measurementId: "G-7C60C9FBVN"
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app(); // if already initialized, use that one
 }
  const auth = firebase.auth();
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/team-1-800x800.jpg").default}
            />
          </span>
        </div>
      </a>
       <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
       {localStorage.getItem('role')=="admin" && <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {e.preventDefault(); closeDropdownPopover(); props.props.history.push('/users/add');}}
        >
          Add Users
        </a>}
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {e.preventDefault(); localStorage.clear(); sessionStorage.clear();  auth.signOut(); dispatch(LoginAction("","",true));}}
        >
         Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
