import React, { useState } from 'react'
import pdfFile from './../../assets/pdf/MODO.pdf'
import PDF from "react-pdf-js";
import { FormControlLabel, Switch } from "@material-ui/core";
import {FaArrowLeft,FaArrowRight} from "react-icons/fa"

function HelpView() {
    const [numPages, setNumPages] = useState(null);
    const [tour,setTour]=useState(false);
  const [pageNumber, setPageNumber] = useState(null);
  const [file, setFile] = useState(pdfFile);
  function onDocumentLoadSuccess({ numPages }) {
      console.log(numPages)
    setNumPages(numPages);
  }
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };
  
 const onDocumentComplete=(pages)=> {
   setNumPages(pages);
    setPageNumber(1);
  }

  const handlePrevious=()=> {
    if(pageNumber-1>0)
    setPageNumber( pageNumber-1);
  }

  const handleNext=() => {
    if(pageNumber+1<=numPages)
    setPageNumber( pageNumber+1);
  }
  const renderPagination=()=> {
    return (
      <>
      <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",width:"50%"}}>
        <button onClick={handlePrevious}><FaArrowLeft size={30}></FaArrowLeft></button>
        <button onClick={handleNext}><FaArrowRight size={30}></FaArrowRight></button>
        </div>
      </>
    );
  }

    return (
        <>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",width:100+"%"}}>
        <PDF
          file={file}
          page={pageNumber}
          onDocumentComplete={(pages)=>{onDocumentComplete(pages)}}
          scale={1}
        />
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:100+"%"}}>
        {pageNumber? renderPagination():null}
        </div>
      </div>
        </>
    )
}

export default HelpView
