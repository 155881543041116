import React, { useState,useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import { Switch, Route, Redirect } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useSelector,useDispatch,connect } from 'react-redux';
import ab2str from 'arraybuffer-to-string';
// components
import Pagination from 'react-responsive-pagination';
import './bootstapmin.css'
import CardTable from "components/Cards/CardTable.js";
import DataAction from "actions/data";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import host from "config/host";


function FileData(props) {

  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const [file,setFile] = useState(false);
  const [data,setData]=useState(null);
  const [currentPage,setCurrentPage]=useState(1);
  const [totalnumberofpages,settotalnumberofpages]=useState(1);
  props.data.then(value=>{
    // setData(value.data)
    if(value.data!=null && value.data.filesList.length!=0){
    setData(value.data.filesList)
    setFile(true)
    }
  })
  
  useEffect(() => {
    fetch(host.nameserver + '/file/?limit=10&skip='+(currentPage-1)*10, {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) return res.json();
      })
      .then(async(data) => {
        console.log(data)
        settotalnumberofpages(Math.ceil(data.count/10))
          dispatch(DataAction(data))
      })
      .catch((err) => {
        setLoading(false);
          console.log(err)
      });
  }, [currentPage])

  useEffect(()=>{
    setTimeout(()=>{
      fetch(host.nameserver + '/file/?limit=10&skip='+(currentPage-1)*10, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('token'),
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) return res.json();
        })
        .then(async(data) => {
          console.log(data)
          settotalnumberofpages(Math.ceil(data.count/10))
            dispatch(DataAction(data))
        })
        .catch((err) => {
          setLoading(false);
            console.log(err)
        });
    },1000)
  },[])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = async (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        setData(data);
        await dispatch(DataAction(data))
        setFile(true)
      };

      fileReader.onerror = (error) => {
        console.log(error);
      }
    })
    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <>
  
  {data==null ? <div style={{marginTop:10, height:70+"vh",textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}><h1>{!loading ? 'Nothing to show here, upload data':'Loading the data please wait....'}</h1></div>:null}
     {file && data!=null && <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4 ">
         
      
        <div className="w-full mb-12 px-4" style={{height:50+"vh",marginTop:100}}>
          <CardTable color="light" data={data} history={props.history} />
        </div>
  </div>
  <div style={{marginTop:100,display:"flex",justifyContent:"center",alignItems:"center",width:100+"%"}}>
  <Pagination
      current={currentPage}
      total={totalnumberofpages}
      onPageChange={(num)=>{setCurrentPage(num); props.history.push('#')}}
      
    />
    </div>
  </div>} 
  
  </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
});

export default connect(mapStateToProps)(FileData)