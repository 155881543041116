import DataReducer from 'reducers/data';
import GuideReducer from 'reducers/guide';
import JobDataReducer from 'reducers/jobdata';
import LoginReducer from 'reducers/Login';
import ResultsFilterReducer from 'reducers/resultsFilter';
import DashboardDataReducer from 'reducers/dashboard';
import {combineReducers} from 'redux';
import ResultsDataReducer from 'reducers/resultsdata';

const RootReducer = combineReducers({
    LoginReducer:LoginReducer,
    DataReducer:DataReducer,
    JobDataReducer:JobDataReducer,
    ResultsFilterReducer:ResultsFilterReducer,
    GuideReducer:GuideReducer,
    DashboardData:DashboardDataReducer,
    ResultsData:ResultsDataReducer
})
export default RootReducer;