const defaultState={
    jobs:"All Jobs",
    level1:true,
    level2:true,
    level3:true,
    level4:true,
    level5:true,
    data:null,
    loading:false,
    totalpageno:1
}
const ResultsFilterReducer =async (state=defaultState,action)=>{
    switch(action.type){
        case "FILTERRESULT": 
            return {...state,jobs:action.payload.data.jobs, level1:action.payload.data.levels.level1,level2:action.payload.data.levels.level2,level3:action.payload.data.levels.level3,level4:action.payload.data.levels.level4,level5:action.payload.data.levels.level5,data:action.payload.data.data,loading:action.payload.data.loading,totalpageno:action.payload.data.totalpageno}
        case "RESULTFAIL":
            return {...state,data:null}
        default:
            return state;
    }

}
export default ResultsFilterReducer;